<template>
  <el-dialog
    :title="type == 'add' ? '添加楼盘' : '修改楼盘'"
    v-model="dialogFormVisible"
  >
    <el-form :model="form">
      <el-form-item label="活动名称" :label-width="120">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="活动区域" :label-width="120">
        <el-select v-model="form.region" placeholder="请选择活动区域">
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { reactive, ref } from "vue";
export default {
  name: "DialogLou",
  props: {
    type: String,
    dialogFormVisible: Boolean,
  },
  setup() {
    const dialogFormVisible = ref(false);
    const form = reactive({
      name: "",
      region: "",
    });

    return {
      dialogFormVisible,
      form,
    };
  },
};
</script>
