<template>
  <div class="information">
    <el-row :gutter="5">
      <el-col :span="3">
        <div class="grid-content bg-purple">
          <div class="information-title">楼盘信息</div>
          <div class="operating">
            <el-button
              type="primary"
              plain
              icon="el-icon-search"
              size="mini"
              @click="addLoupan"
              >添加</el-button
            >
            <el-button
              type="primary"
              plain
              icon="el-icon-search"
              size="mini"
              @click="editLoupan"
              >修改</el-button
            >
            <el-button
              type="primary"
              plain
              icon="el-icon-search"
              size="mini"
              @click="postRemoveLoupan"
              >删除</el-button
            >
          </div>
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            highlight-current-row
            @current-change="handleCurrent"
            max-height="800"
          >
            <el-table-column prop="date" label="编号" width="50">
            </el-table-column>
            <el-table-column prop="name" label="名称"> </el-table-column>
          </el-table>
        </div>
      </el-col>
      <el-col :span="3"
        ><div class="grid-content bg-purple-light">
          <div class="information-title">{{ juminInformation }}</div>
          <div class="operating">
            <el-button
              type="primary"
              plain
              icon="el-icon-search"
              size="mini"
              @click="addUnit"
              >添加</el-button
            >
            <el-button
              type="primary"
              plain
              icon="el-icon-search"
              size="mini"
              @click="editUnit"
              >修改</el-button
            >
            <el-button
              type="primary"
              plain
              icon="el-icon-search"
              size="mini"
              @click="removeUnit"
              >删除</el-button
            >
          </div>
          <el-table
            :data="tableDataUnits"
            border
            style="width: 100%"
            @current-change="houseImformation"
            highlight-current-row
            max-height="840"
          >
            <el-table-column prop="index" label="序号" width="50">
            </el-table-column>
            <el-table-column prop="number" label="名称(栋)"> </el-table-column>
          </el-table>
        </div>
      </el-col>
      <el-col :span="18" class="el-col-right">
        <div class="grid-content bg-purple">
          <div class="information-title">
            {{ currentUnit.unit_name
            }}{{ currentUnit.unit_name ? "-" : "" }}房屋信息
          </div>
          <div class="operating">
            <el-dropdown @command="houseImport">
              <el-button>
                <i class="el-icon-edit"></i>
                房屋导入
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="a">导入格式</el-dropdown-item>
                  <el-dropdown-item command="b">批量导入</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button @click="addHouse"
              ><i class="el-icon-plus"></i>添加</el-button
            >
            <el-button @click="edithouse"
              ><i class="el-icon-setting"></i>修改</el-button
            >
            <el-button @click="removehouse"
              ><i class="el-icon-delete"></i>删除</el-button
            >
            <el-button @click="inJoin"
              ><i class="el-icon-user"></i>入伙</el-button
            >
            <el-dropdown @command="jionImport">
              <el-button>
                <i class="el-icon-edit"></i>
                批量导入
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="a">导入格式</el-dropdown-item>
                  <el-dropdown-item command="b">批量导入</el-dropdown-item>
                  <el-dropdown-item command="c">未入伙导入</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-dropdown @command="bankImport">
              <el-button>
                <i class="el-icon-edit"></i>
                批量设置银行代收
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="a">导入格式</el-dropdown-item>
                  <el-dropdown-item command="b">批量导入</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button @click="bankReplace"
              ><i class="el-icon-plus"></i>设置银行代收</el-button
            >
            <el-button @click="bankCancel"
              ><i class="el-icon-plus"></i>取消银行代收</el-button
            >
            <el-button @click="erweima"
              ><i class="el-icon-plus"></i>绑定二维码</el-button
            >
           <el-button @click="exportcode" 
              ><i class="el-icon-plus"></i>房屋编码导出</el-button
            >
          </div>
          <el-table
            :data="tableDatahouse"
            border
            style="width: 100%"
            highlight-current-row
            @current-change="handleHouseCurrent"
            max-height="800"
          >
            <el-table-column prop="index" label="编号" width="50">
            </el-table-column>
            <el-table-column prop="title" label="名称"> </el-table-column>
            <el-table-column prop="bangding" label="绑定码"> </el-table-column>
            <el-table-column prop="name" label="姓名"> </el-table-column>
            <el-table-column prop="phone" label="手机"> </el-table-column>
            <el-table-column prop="house" label="房屋面积"> </el-table-column>
            <el-table-column prop="shiba" label="柴房面积"> </el-table-column>
            <el-table-column prop="state" label="是否绑定"> </el-table-column>
            <el-table-column prop="join" label="是否入伙"> </el-table-column>
            <el-table-column prop="bank" label="银行代收"> </el-table-column>
            <el-table-column prop="charge" label="公摊面积"> </el-table-column>
          </el-table>
          <!-- pagination -->
          <div class="block">
            <el-pagination
              background
              @current-change="handlePagination"
              v-model:currentPage="currentPage1"
              :page-size="15"
              layout="total, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

  <el-dialog
    :title="type == 'add' ? '添加楼盘' : '修改楼盘'"
    v-model="dialogFormVisible"
  >
    <el-form :model="form" label-width="100px" :rules="rules" ref="formRef">
      <el-form-item label="楼盘名称" prop="name">
        <el-input type="text" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="卫生费" prop="health">
        <el-input type="number" v-model="form.health"></el-input>
      </el-form-item>
      <el-form-item label="水费" prop="water">
        <el-input type="number" min="0" v-model="form.water"></el-input>
      </el-form-item>
      <el-form-item label="柴房物业费" prop="wood">
        <el-input type="number" v-model="form.wood"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitLoupanForm">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 单元 提交弹出框 -->
  <el-dialog
    :title="type == 'add' ? '添加居民楼' : '修改居民楼'"
    v-model="juminVisible"
  >
    <el-form
      :model="formjumin"
      label-width="100px"
      :rules="rulesjumin"
      ref="formunit"
    >
      <el-form-item label="楼名称" prop="name">
        <el-input type="text" v-model="formjumin.name"></el-input>
      </el-form-item>
      <el-form-item label="物业管理费" prop="property_fee">
        <el-input type="number" v-model="formjumin.property_fee"></el-input>
      </el-form-item>
      <el-form-item label="维修基金" prop="maintenance_fee">
        <el-input type="number" v-model="formjumin.maintenance_fee"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="juminVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUnitForm">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 房屋 弹框 -->
  <el-dialog
    :title="type == 'add' ? '添加房屋信息' : '修改房屋信息'"
    v-model="houseVisible"
  >
    <el-form
      :model="formhouse"
      label-width="100px"
      :rules="ruleshouse"
      ref="formhouseRef"
    >
      <el-form-item label="房号" prop="room_name">
        <el-input type="text" v-model="formhouse.room_name"></el-input>
      </el-form-item>
      <el-form-item label="房屋面积" prop="floor_space">
        <el-input type="number" v-model="formhouse.floor_space"></el-input>
      </el-form-item>
      <el-form-item label="柴房面积" prop="wood_room">
        <el-input type="number" v-model="formhouse.wood_room"></el-input>
      </el-form-item>
      <el-form-item label="业主姓名" prop="user_name">
        <el-input
          type="text"
          v-model="formhouse.user_name"
          placeholder="如有多个请用|号隔开"
        ></el-input>
      </el-form-item>
      <el-form-item label="业主手机号" prop="phone">
        <el-input type="number" v-model="formhouse.phone"></el-input>
      </el-form-item>
      <el-form-item label="公摊面积" prop="fee">
        <el-input type="number" v-model="formhouse.fee"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="houseVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitHouseForm">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 添加房屋和批量导入 -->

  <el-dialog :title="importTitle" v-model="houseImportModel">
    <input
      class="upload"
      type="file"
      ref="upload"
      accept="MIME_type"
      @change="uploadFile($event)"
    />

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="houseImportModel = false">取 消</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog title="绑定二维码" v-model="erweimaModel">
    <qrcode-vue :value="erweimaValue" :size="200"> </qrcode-vue>
  </el-dialog>
</template>

<script>
import { reactive, ref, toRefs } from "vue";
import axios from "axios";
import DialogLou from "../components/DialogLou.vue";
import QrcodeVue from "qrcode.vue";
// @ is an alias to /src
import {
  loupan,
  loupanUnits,
  loupanHouse,
  removeLoupan,
  removeLoupanUnit,
  postremovehouse,
  postJoin,
  postBank,
  houseBatchImport,
} from "../http/information.js";
import { ElMessage, ElMessageBox } from "element-plus";
import $http from "../http/index.js";
import {
		readAccount
	} from "../utils/localStorage";
import {
		useStore
	} from "vuex";
export default {
  name: "Information",
  components: {
    DialogLou,
    QrcodeVue,
  },
  computed:{
	Permission1:function () {
				return (this.store.state.account || readLocalAccount) == 'admin';
				
				//console.log('account Permission1:'+this.store.state.account)
				//console.log('account Permission1:'+(this.store.state.account=='admin'))
				//return this.store.state.account=='admin';
			}
  },
  setup() {
    const erweimaValue = ref("");
    const erweimaModel = ref(false);
    const formdata = reactive({});
    const houseImportModel = ref(false);
    const juminVisible = ref(false);
    const dialogFormVisible = ref(false);
    const houseVisible = ref(false);
    const store = useStore();
  	const readLocalAccount = readAccount();
    const importTitle = ref("");
    let addLou = ref(null);
    let type = ref("");
    let configId = ref(0);
    const totalCount = ref(0);
    const currentPage1 = ref(1);
    let tableData = reactive([]);
    let tableDataUnits = reactive([]);
    const tableDatahouse = reactive([]);
    let current = reactive({
      loupanId: 0,
      juminInformation: "居民楼信息",
      health_costs: "",
      water: "",
      wood_house: "",
    });
    let currentUnit = reactive({
      unitId: null,
      property_fee: "",
      maintenance_fee: "",
      unit_name: "",
    });
    let currentHouse = reactive({
      title: "",
      bangding: "",
      name: "",
      phone: "",
      house: "",
      shiba: "",
      state: "",
      join: "",
      bank: "",
      charge: "",
      id: "",
    });
    const formRef = ref(null);
    const formunit = ref(null);
    const formhouseRef = ref(null);
    let form = reactive({
      name: "",
      health: "",
      water: "",
      wood: "",
    });
    let formjumin = reactive({
      name: "",
      property_fee: "",
      maintenance_fee: "",
    });
    let formhouse = reactive({
      room_name: "",
      floor_space: "",
      wood_room: "",
      user_name: "",
      phone: "",
      fee: "",
    });
    let ruleshouse = reactive({
      room_name: [
        { required: "true", message: "名称不能为空", trigger: ["blur"] },
      ],
      floor_space: [
        { required: "true", message: "房屋面积不能为空", trigger: ["blur"] },
      ],
      wood_room: [
        { required: "true", message: "柴房面积不能为空", trigger: ["blur"] },
      ],
      user_name: [
        {
          required: "true",
          message: "业主姓名不能为空",
          trigger: ["blur"],
        },
      ],
      phone: [
        {
          required: "true",
          message: "手机号码不能为空",
          trigger: ["blur"],
        },
      ],
      fee: [
        {
          required: "true",
          message: "公摊电费不能为空",
          trigger: ["blur"],
        },
      ],
    });

    let rules = reactive({
      name: [{ required: "true", message: "名称不能为空", trigger: ["blur"] }],
      health: [
        { required: "true", message: "卫生费不能为空", trigger: ["blur"] },
      ],
      water: [{ required: "true", message: "水费不能为空", trigger: ["blur"] }],
      wood: [
        {
          required: "true",
          message: "柴房物业费不能为空",
          trigger: ["blur"],
        },
      ],
    });
    let rulesjumin = reactive({
      name: [{ required: "true", message: "楼号不能为空", trigger: ["blur"] }],
      property_fee: [
        { required: "true", message: "物业费不能为空", trigger: ["blur"] },
      ],
      maintenance_fee: [
        { required: "true", message: "水维修费不能为空", trigger: ["blur"] },
      ],
    });
    //提交楼盘表单
    const submitLoupanForm = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          if (type.value == "add") {
            $http
              .post("/Building/SetBuilding", {
                status: "add",
                id: "",
                building_name: form.name,
                health_costs: form.health,
                water: form.water,
                wood_house: form.wood,
              })
              .then((res) => {
                // console.log(res.data.data);
                let state = res.data.data.state;
                // if (state == 0) {
                //   ElMessage.success("添加成功");
                //   dialogFormVisible.value = false;
                //   getLoupan();
                // } else if (state == 1) {
                //   ElMessage.error("添加失败");
                // }
                ElMessage.success("添加成功");
                dialogFormVisible.value = false;
                getLoupan();
              })
              .catch((res) => {
                ElMessage.error("添加失败");
              });
          } else {
            $http
              .post("/Building/SetBuilding", {
                status: "modify",
                id: current.loupanId,
                building_name: form.name,
                health_costs: form.health,
                water: form.water,
                wood_house: form.wood,
              })
              .then((res) => {
                let data = res.data;
                let state = data.state;
                if (state == 0) {
                  ElMessage.success("修改成功");
                  dialogFormVisible.value = false;
                  getLoupan();
                } else if (state == 1) {
                  ElMessage.error(data.message);
                  //  dialogFormVisible.value = false;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      });
    };
    //

    //方法们
    getLoupan();

    //获取楼盘信息
    function getLoupan() {
      loupan()
        .then((res) => {
          tableData.length = 0;
          let louData = res.data.data;
          for (let i = 1; i <= louData.length; i++) {
            let refData = {
              date: i,
              name: louData[i - 1].building_name,
              id: louData[i - 1].id,
              health_costs: louData[i - 1].health_costs,
              water: louData[i - 1].water,
              wood_house: louData[i - 1].wood_house,
            };
            tableData.push(refData);
          }
        })
        .catch(() => {});
    }
    //增加楼盘信息
    function addLoupan() {
      type.value = "add";
      dialogFormVisible.value = true;
      (form.name = ""), (form.health = ""), (form.water = ""), (form.wood = "");
    }
    //编辑楼盘
    function editLoupan() {
      type.value = "modify";
      if (current.loupanId) {
        dialogFormVisible.value = true;
        (form.name = current.juminInformation),
          (form.health = current.health_costs);
        form.water = current.water;
        form.wood = current.wood_house;
      } else {
        ElMessage.warning({
          message: "请选择要修改的记录~",
        });
      }
    }
    //删除楼盘信息
    function postRemoveLoupan() {
      if (current.loupanId) {
        removeLoupan(current.loupanId).then((res) => {
          let data = res.data;
          let state = data.state;
          if (state == 0) {
            ElMessage.success("删除成功");
            getLoupan();
          } else if (state == 1) {
            ElMessage.error(data.message);
          }
        });
      } else {
        ElMessage.warning({
          message: "选择要删除的选项",
          type: "warning",
        });
      }
    }

    // 获取单元
    let currentIndex = -1;
    function handleCurrent(currentRow) {
      current.juminInformation = currentRow.name;
      current.loupanId = currentRow.id;
      current.health_costs = currentRow.health_costs;
      current.water = currentRow.water;
      current.wood_house = currentRow.wood_house;
      if (currentRow != currentRow.date) {
        tableDataUnits.length = 0;
        getLoupanUnit(currentRow.id);
        currentIndex == currentRow.date;
      }
    }

    //封装 获取单元核心
    function getLoupanUnit(id) {
      loupanUnits(id).then((res) => {
        let unitsData = res.data.data;
        for (let i = 1; i <= unitsData.length; i++) {
          let refData = {
            index: i,
            number: unitsData[i - 1].unit_name,
            id: unitsData[i - 1].id,
            property_fee: unitsData[i - 1].property_fee,
            maintenance_fee: unitsData[i - 1].maintenance_fee,
          };
          tableDataUnits.push(refData);
        }
        tableDatahouse.length = 0;
      });
    }

    //添加单元楼信息
    function addUnit() {
      if (current.loupanId) {
        type.value = "add";
        juminVisible.value = true;
        (formjumin.name = ""),
          (formjumin.property_fee = ""),
          (formjumin.maintenance_fee = "");
      } else {
        ElMessage.error("请先选择楼盘信息");
      }
    }
    //编辑单元楼信息
    function editUnit() {
      type.value = "modify";
      if (currentUnit.unitId) {
        juminVisible.value = true;
        (formjumin.name = currentUnit.unit_name),
          (formjumin.property_fee = currentUnit.property_fee);
        formjumin.maintenance_fee = currentUnit.maintenance_fee;
      } else {
        ElMessage.warning({
          message: "请选择要修改的记录~",
        });
      }
    }

    //删除单元信息

    function removeUnit() {
      if (currentUnit.unitId) {
        removeLoupanUnit(current.loupanId, currentUnit.unitId)
          .then((res) => {
            let data = res.data;
            let state = data.state;
            console.log(data);
            if (state == 0) {
              ElMessage.success("删除成功");
              // getLoupan();
            } else if (state == 1) {
              ElMessage.error("该单元存在子项，请先删除");
            }
          })
          .catch((err) => {
            ElMessage.error("删除失败");
          });
      } else {
        ElMessage.warning({
          message: "其选择要删除的选项",
          type: "warning",
        });
      }
    }
    //  提交单元表单  submitUnitForm
    function submitUnitForm() {
      formunit.value.validate((valid) => {
        if (valid) {
          if (type.value == "add") {
            $http
              .post("/Building/SetBuildingUnit", {
                status: "add",
                building_id: current.loupanId,
                id: "",
                unit_name: formjumin.name,
                property_fee: formjumin.property_fee,
                maintenance_fee: formjumin.maintenance_fee,
              })
              .then((res) => {
                let data = res.data;
                let state = data.state;
                if (state == 0) {
                  ElMessage.success("添加成功");
                  juminVisible.value = false;
                } else if (state == 1) {
                  ElMessage.error(data.message);
                  juminVisible.value = false;
                }
              })
              .catch((err) => {
                // ElMessage.error("添加失败");
                // console.log(err)
              });
          } else {
            $http
              .post("/Building/SetBuildingUnit", {
                status: "modify",
                building_id: current.loupanId,
                id: currentUnit.unitId,
                unit_name: formjumin.name,
                property_fee: formjumin.property_fee,
                maintenance_fee: formjumin.maintenance_fee,
              })
              .then((res) => {
                let data = res.data;
                let state = data.state;
                console.log(data);
                if (state == 0) {
                  ElMessage.success("修改成功");
                  juminVisible.value = false;
                  getLoupan();
                } else if (state == 1) {
                  ElMessage.error(data.message);
                  //  dialogFormVisible.value = false;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      });
    }

    //获取房屋信息                                                            *********房屋*******

    function houseImformation(val) {
      currentHouse.id = "";
      currentPage1.value = 1;
      currentUnit.unitId = val.id;
      currentUnit.unit_name = val.number;
      currentUnit.property_fee = val.property_fee;
      currentUnit.maintenance_fee = val.maintenance_fee;
      getHouse();
    }
    //封装获取房屋核心
    function getHouse() {
      loupanHouse(currentUnit.unitId, 15, 1).then((res) => {
        tableDatahouse.length = 0;
        let data = res.data.data;
        console.log(data);
        totalCount.value = data.count;
        let totalData = data.total;
        for (let i = 1; i <= totalData.length; i++) {
          let refData = {
            index: i,
            title: totalData[i - 1].room_name,
            bangding: totalData[i - 1].code,
            name: totalData[i - 1].user_name,
            phone: totalData[i - 1].phone,
            house: totalData[i - 1].floor_space,
            shiba: totalData[i - 1].wood_room,
            state: Boolean(totalData[i - 1].status) ? "是" : "否",
            join: Boolean(totalData[i - 1].is_fee) ? "是" : "否",
            bank: Boolean(totalData[i - 1].bank_status) ? "是" : "否",
            charge: totalData[i - 1].fee,
            id: totalData[i - 1].id,
            code: totalData[i - 1].code,
          };
          tableDatahouse.push(refData);
        }
      });
    }
    //选择房屋
    function handleHouseCurrent(val) {
      currentHouse.title = val.title;
      currentHouse.bangding = val.bangding;
      currentHouse.name = val.name;
      currentHouse.phone = val.phone;
      currentHouse.house = val.house;
      currentHouse.shiba = val.shiba;
      currentHouse.state = val.state;
      currentHouse.join = val.join;
      currentHouse.bank = val.bank;
      currentHouse.charge = val.charge;
      currentHouse.id = val.id;
      currentHouse.code = val.code;
      console.log(val);
    }

    // 添加房屋
    function addHouse() {
      if (currentUnit.unitId) {
        type.value = "add";
        houseVisible.value = true;
        (formhouse.number = ""),
          (formhouse.floor_space = ""),
          (formhouse.wood_room = ""),
          (formhouse.user_name = ""),
          (formhouse.phone = ""),
          (formhouse.fee = "");
      } else {
        ElMessage.error("请先选择居民楼");
      }
    }
    //编辑房屋
    function edithouse() {
      type.value = "modify";
      if (currentHouse.id) {
        houseVisible.value = true;
        (formhouse.room_name = currentHouse.title),
          (formhouse.floor_space = currentHouse.house),
          (formhouse.wood_room = currentHouse.shiba),
          (formhouse.user_name = currentHouse.name),
          (formhouse.phone = currentHouse.phone),
          (formhouse.fee = currentHouse.charge);
      } else {
        ElMessage.warning({
          message: "请选择要修改的记录~",
        });
      }
    }

    //删除房屋
    function removehouse() {
      if (currentHouse.id) {
        postremovehouse(currentHouse.id)
          .then((res) => {
            let data = res.data;
            let state = data.state;
            if (state == 0) {
              ElMessage.success("删除成功");
              getHouse();
            } else if (state == 1) {
              ElMessage.error(data.message);
            }
          })
          .catch((err) => {
            ElMessage.error("删除失败");
          });
      } else {
        ElMessage.warning({
          message: "选择要删除的选项",
          type: "warning",
        });
      }
    }

    //提交房屋表单
    const submitHouseForm = () => {
      formhouseRef.value.validate((valid) => {
        if (valid) {
          if (type.value == "add") {
            $http
              .post("/Building/SetUnitRoom", {
                status: "add",
                id: "",
                unit_id: currentUnit.unitId,
                room_name: formhouse.room_name,
                floor_space: formhouse.floor_space,
                wood_room: formhouse.wood_room,
                user_name: formhouse.user_name,
                phone: formhouse.phone,
                fee: formhouse.fee,
              })
              .then((res) => {
                let data = res.data;
                let state = data.state;
                if (state == 0) {
                  ElMessage.success("添加成功");
                  houseVisible.value = false;
                  getHouse();
                } else if (state == 1) {
                  ElMessage.error(data.message);
                }
              })
              .catch((res) => {
                ElMessage.error("添加失败");
              });
          } else {
            $http
              .post("/Building/SetUnitRoom", {
                status: "modify",
                id: currentHouse.id,
                unit_id: currentUnit.unitId,
                room_name: formhouse.room_name,
                floor_space: formhouse.floor_space,
                wood_room: formhouse.wood_room,
                user_name: formhouse.user_name,
                phone: formhouse.phone,
                fee: formhouse.fee,
              })
              .then((res) => {
                let data = res.data;
                let state = data.state;
                if (state == 0) {
                  ElMessage.success("修改成功");
                  houseVisible.value = false;
                  currentHouse.id = "";
                  getHouse();
                } else if (state == 1) {
                  ElMessage.error(data.message);
                  //  dialogFormVisible.value = false;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      });
    };

    //入伙
    function inJoin() {
      if (currentHouse.id) {
        ElMessageBox.confirm(
          "你确定要入伙吗?入伙后即将开始收取物业费等费用",
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then((res) => {
            postJoin(currentHouse.id)
              .then((res) => {
                let data = res.data;
                let state = data.state;
                if (state == 0) {
                  ElMessage.success("入伙成功~");
                  getHouse();
                } else if (state == 1) {
                  ElMessage.error("入伙失败");
                }
              })
              .catch((err) => {
                ElMessage.error("操作失败");
                console.log(err);
              });
          })
          .catch(() => {});
      } else {
        ElMessage.error("请选择一项");
      }
    }
    // 设置银行代收

    function bankReplace() {
      if (currentHouse.id) {
        if (currentHouse.state == "是") {
          ElMessage.warning("当前为银行代收");
        } else if (currentHouse.state == "否") {
          postBank(1, currentHouse.id)
            .then((res) => {
              let data = res.data;
              let state = data.state;
              if (state == 0) {
                ElMessage.success("代收成功");
                getHouse();
              } else if (state == 1) {
                ElMessage.error(data.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        ElMessage.error("请先选择一项");
      }
    }
    //取消银行代收
    function bankCancel() {
      if (currentHouse.id) {
        if (currentHouse.bank == "否") {
          ElMessage.warning("银行代收为否");
        } else if (currentHouse.bank == "是") {
          postBank(0, currentHouse.id)
            .then((res) => {
              let data = res.data;
              let state = data.state;
              if (state == 0) {
                ElMessage.success("取消代收成功");
                getHouse();
              } else if (state == 1) {
                ElMessage.error(data.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        ElMessage.error("请先选择一项");
      }
    }

    // 房屋导入
    function houseImport(command) {
      if (command == "a") {
        window.location.href =
          "https://rhdmx.com/ExcelFile/GetExcelFile?name=roomgs";
      } else if (command == "b") {
        importTitle.value = "添加房屋信息";
        houseImportModel.value = true;
      }
    }
    //选择文件
    function uploadFile(e) {
      let file = e.target.files[0];
      let formdata = new FormData();
      let importUrl = "";
      formdata.append("file", file);
      // let config = {
      //   headers:{ "Content-Type": "multipart/form-data" }
      // }

      if (importTitle.value == "添加房屋信息") {
        importUrl =
          "https://rhdmx.com/Building/BatchFileHandel?mathon=BatchAddRoom";
      } else if (importTitle.value == "添加入伙信息") {
        importUrl =
          "https://rhdmx.com/Building/BatchFileHandel?mathon=BatchRuHuo";
      } else if (importTitle.value == "添加未入伙信息") {
        importUrl =
          "https://rhdmx.com/Building/BatchFileHandel?mathon=BatchNoRuHuo";
      } else if (importTitle.value == "批量设置银行代收") {
        importUrl =
          "https://rhdmx.com/Building/BatchFileHandel?mathon=setRoomBank";
      }
      axios
        .post(importUrl, formdata)
        .then((res) => {
          let data = res.data;
          let state = data.state;
          if (state == 0) {
            houseImportModel.value = false;
            ElMessage.success("导入成功");
          } else if (state == 1) {
            ElMessage.error(data.message);
            houseImportModel.value = false;
          }
        })
        .catch((err) => {
          ElMessage.message(err);
        });
    }

    //入伙导入
    function jionImport(command) {
      if (command == "a") {
        window.location.href =
          "https://rhdmx.com/ExcelFile/GetExcelFile?name=ruhuo";
      } else if (command == "b") {
        importTitle.value = "添加入伙信息";
        houseImportModel.value = true;
      } else if (command == "c") {
        importTitle.value = "添加未入伙信息";
        houseImportModel.value = true;
      }
    }

    //银行代收

    function bankImport(command) {
      if (command == "a") {
        window.location.href =
          "https://rhdmx.com/ExcelFile/GetExcelFile?name=bank";
      } else if (command == "b") {
        importTitle.value = "批量设置银行代收";
        houseImportModel.value = true;
      }
    }
    //发送房屋批量导入
    function submitHouseImport() {
      // houseBatchImport(formdata).then((res)=>{
      //   console.log(res)
      // })
    }

    //二维码

    function erweima() {
      if (currentHouse.id) {
        console.log(currentHouse.code);
        erweimaValue.value =
          "https://rhdmx.com/weixin/zzbinding?code=" + currentHouse.code;

        erweimaModel.value = true;
      } else {
        ElMessage.warning("请选择一项");
      }
    }

    function exportcode(){
      window.location.href= "https://rhdmx.com/building/exportcode";
    }
    //pagination 翻页

    function handlePagination(val) {
      loupanHouse(currentUnit.unitId, 15, val).then((res) => {
        console.log(res);
        tableDatahouse.length = 0;
        let data = res.data.data;
        let totalData = data.total;
        for (let i = 1; i <= totalData.length; i++) {
          let refData = {
            index: i + (val - 1) * 15,
            title: totalData[i - 1].room_name,
            bangding: totalData[i - 1].code,
            name: totalData[i - 1].user_name,
            phone: totalData[i - 1].phone,
            house: totalData[i - 1].floor_space,
            shiba: totalData[i - 1].wood_room,
            state: Boolean(totalData[i - 1].status) ? "是" : "否",
            join: Boolean(totalData[i - 1].is_fee) ? "是" : "否",
            bank: Boolean(totalData[i - 1].bank_status) ? "是" : "否",
            charge: totalData[i - 1].fee,
            id: totalData[i - 1].id,
          };
          tableDatahouse.push(refData);
        }
      });
    }

    return {
            store,
      erweimaValue,
      erweimaModel,
      erweima,
      exportcode,
      bankCancel,
      submitHouseImport,
      formdata,
      uploadFile,
      bankImport,
      jionImport,
      houseImportModel,
      importTitle,
      bankReplace,
      inJoin,
      removehouse,
      handleHouseCurrent,
      edithouse,
      submitHouseForm,
      formhouseRef,
      addHouse,
      ruleshouse,
      formhouse,
      houseVisible,
      tableData,
      handleCurrent,
      tableDataUnits,
      houseImformation,
      handlePagination,
      currentPage1,
      tableDatahouse,
      totalCount,
      postRemoveLoupan,
      addLoupan,
      type,
      configId,
      addLou,
      dialogFormVisible,
      form,
      rules,
      submitLoupanForm,
      formRef,
      editLoupan,
      ...toRefs(current),
      juminVisible,
      submitUnitForm,
      formjumin,
      rulesjumin,
      addUnit,
      formunit,
      currentUnit,
      editUnit,
      removeUnit,
      ...toRefs(currentHouse),
      houseImport,
    };
  },
};
</script>
<style scoped>
.information {
  width: 100%;
  height: 100%;
}
.el-row {
  height: 100%;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  height: 100%;
}
.el-col {
  border-radius: 4px;
  height: 100%;
  min-width: 100px;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}

.information-title {
  font-size: 12px;
  padding: 5px;
  background: linear-gradient(to bottom, #eff5ff 0, #e0ecff 100%);
}
.operating {
  overflow: hidden;
  text-align: center;
  padding: 5px 0;
}
.el-button {
  margin: 0;
  padding: 7px;
}
div.el-dialog {
  width: 450px;
}
.el-col-right {
  height: 100%;
  position: relative;
}
.block {
  position: absolute;
  bottom: 0;
}

/* /deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #dfe6ec;
} */

/deep/.el-table__body tr.current-row > td {
  background-color: #ffe48d;
}
</style>
