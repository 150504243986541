import  $http from './index'
import axios from 'axios'
//楼盘信息
export function loupan(){
    return $http.get('/Building/GetBuildings')
}
   // 删除楼盘
        export function  removeLoupan(id){
          return $http.post('/Building/RemoveBuilding',{
            id:id
          })
        }
     

//获取楼盘单元

export function loupanUnits(id){
    return $http.get('/Building/getBuildingUnits',{
        params: {
            buildingId: id
          }
    })
} 
    //  删除单元
     export function removeLoupanUnit(loupanID,unitId){
        return $http.post('/Building/RemoveBuildingUnit',{
          building_id:loupanID,
          id:unitId
        })
     }





//房屋信息

export function loupanHouse(id,row,page){
    return $http.get('/Building/searchUnitRoomGson',{
      params:{
        rows:row,
        page:page,
        unit_id:id
      }
    })
  }

  //删除房屋

   export function postremovehouse(houseId){
      return $http.post('/Building/RemoveUnitRoom',{
        id:houseId
      })
   }

   //入伙
   export function postJoin (id){
     return $http.post('/Building/updateUnitRoomJL',{
       id:id
     })
   }

   //银行代收

   export function postBank(status,id){
      return $http.post('/Building/updateUnitRoomStatus',{
        status:status,
        id:id
      })
   }

   export function gethouseXlsx(){
     return axios.get('https://rhdmx.com/images/excel/roomgs.xlsx')
   }


   export function houseBatchImport(data){
     return $http.post('/Building/BatchFileHandel?mathon=BatchAddRoom',data)
   }
  